import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "clutch/src/TextInput/TextInput.jsx";

import { AvgDamageText } from "@/game-val/CommonComponent.jsx";
import SelectAct from "@/game-val/SelectAct.jsx";
import SelectMap from "@/game-val/SelectMap.jsx";
import SelectMode from "@/game-val/SelectMode.jsx";
import SelectRank from "@/game-val/SelectRank.jsx";
import SelectWeaponType from "@/game-val/SelectWeaponType.jsx";
import type { WeaponStatsParams } from "@/game-val/stats-utils.mjs";
import {
  getStatsParams,
  updateStatsParams,
  useGetWeaponsData,
} from "@/game-val/stats-utils.mjs";
import { getWeaponImageByKey } from "@/game-val/utils.mjs";
import { WeaponsCol } from "@/game-val/Weapons.jsx";
import ContentContainer from "@/shared/ContentContainer.jsx";
import DataTable from "@/shared/DataTable.jsx";
import FilterBar from "@/shared/FilterBar.jsx";
import { displayRate } from "@/util/helpers.mjs";
import { getLocaleString } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";

const COLS = (t) => [
  {
    display: t("val:weapon", "Weapon"),
    primary: true,
  },
  {
    display: t("val:stats.killsPerRound", "Kills Per Round"),
    isStat: true,
  },
  {
    display: t("val:stats.altKillsPerRound", "Alt. Kills Per Round"),
    isStat: true,
  },
  {
    display: t("val:stats.headshotPercent", "Headshot %"),
    isStat: true,
  },
  {
    display: t("val:stats.bodyshotPercent", "Bodyshot %"),
    isStat: true,
  },
  {
    display: t("val:stats.legshotPercent", "Legshot %"),
    isStat: true,
  },
  {
    display: t("val:stats.avgDamage", "Avg. Damage"),
    isStat: true,
  },
];

function TableView({ searchText }) {
  const { t } = useTranslation();
  const { searchParams } = useRoute();

  const data = useGetWeaponsData(searchParams, searchText);
  const cols = COLS(t);
  const rows = useMemo(() => {
    if (!data) return null;
    return data.map((stats) => {
      return [
        {
          display: (
            <div className={WeaponsCol()}>
              {stats.key && <img src={getWeaponImageByKey(stats.key)} />}
              <span className="type-body2">{stats.name}</span>
            </div>
          ),
          value: stats.name,
          link: `/valorant/weapons/${stats.key}`,
        },
        {
          display: getLocaleString(stats.killsPerRound, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }),
          value: stats.killsPerRound,
        },
        {
          display: getLocaleString(stats.altKillsPerRound, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }),
          value: stats.altKillsPerRound,
        },
        {
          display: displayRate(stats.headshotPercent, 100, 0, 1),
          value: stats.headshotPercent,
        },
        {
          display: displayRate(stats.bodyshotPercent, 100, 0, 1),
          value: stats.bodyshotPercent,
        },
        {
          display: displayRate(stats.legshotPercent, 100, 0, 1),
          value: stats.legshotPercent,
        },
        {
          display: (
            <AvgDamageText className="type-body2" $score={stats.avgDamage}>
              {getLocaleString(stats.avgDamage, {
                maximumFractionDigits: 1,
                minimumFractionDigits: 1,
              })}
            </AvgDamageText>
          ),
          value: stats.avgDamage,
        },
      ];
    });
  }, [data]);

  return <DataTable cols={cols} rows={rows} indexCol sortCol={6} />;
}

const Filters = ({ searchText, setSearch }) => {
  const { searchParams, currentPath } = useRoute();
  const { t } = useTranslation();

  const { act, rank, map, mode, type } = getStatsParams(
    searchParams,
    "weapons",
  ) as WeaponStatsParams;

  const updateFilters = useCallback(
    (key, val) => {
      const payload: Record<string, string> = { [key]: val };
      // Reset map on change to or from TDM
      if (
        key === "mode" &&
        (mode === "hurm" || val === "hurm") &&
        map !== "all"
      ) {
        payload.map = "all";
      }

      updateStatsParams({ searchParams, currentPath }, payload, "weapons");
    },
    [searchParams, currentPath, map, mode],
  );

  const isCompetitive = useMemo(() => mode === "competitive", [mode]);
  return (
    <FilterBar>
      <TextInput
        defaultValue={searchText}
        placeholder={t("val:search.weapons", "Search weapons")}
        onValueChange={setSearch}
      />
      <SelectWeaponType
        selected={type}
        onChange={(v) => updateFilters("type", v)}
      />
      <SelectMode
        stats
        selected={mode}
        onChange={(v) => updateFilters("mode", v)}
      />
      <SelectMap
        selected={map}
        onChange={(v) => updateFilters("map", v)}
        mode={mode}
      />
      {isCompetitive ? (
        <SelectRank
          selected={rank}
          onChange={(v) => updateFilters("rank", v)}
        />
      ) : null}
      <SelectAct selected={act} onChange={(v) => updateFilters("act", v)} />
    </FilterBar>
  );
};

export default function Weapons() {
  const [searchText, setSearch] = useState("");

  return (
    <ContentContainer className="flex column gap-sp-4">
      <Filters searchText={searchText} setSearch={setSearch} />
      <TableView searchText={searchText} />
    </ContentContainer>
  );
}
