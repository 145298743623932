import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { TextInput } from "clutch/src/TextInput/TextInput.jsx";

import MapBlock from "@/game-val/MapBlockImg.jsx";
import SelectAct from "@/game-val/SelectAct.jsx";
import SelectMode from "@/game-val/SelectMode.jsx";
import SelectRank from "@/game-val/SelectRank.jsx";
import type { MapStatsParams } from "@/game-val/stats-utils.mjs";
import {
  getStatsParams,
  updateStatsParams,
  useGetMapData,
} from "@/game-val/stats-utils.mjs";
import ContentContainer from "@/shared/ContentContainer";
import DataTable from "@/shared/DataTable.jsx";
import FilterBar from "@/shared/FilterBar.jsx";
import { WinRateText } from "@/shared-fps/CommonComponents.jsx";
import { displayRate } from "@/util/helpers.mjs";
import { getLocaleString } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";

const MapCol = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-start;
  flex-grow: 1;
  padding-left: var(--sp-4);
  span {
    margin-left: var(--sp-3);
  }
`;

const COLS = (t) => [
  {
    display: t("common:map", "Map"),
    primary: true,
  },
  {
    display: t("lol:playRate", "Play Rate"),
    isStat: true,
  },
  {
    display: t("val:stats.attackingRoundWinRate", "Atk Round Win %"),
    isStat: true,
  },
  {
    display: t("val:stats.defendingWinRate", "Def Round Win %"),
    isStat: true,
  },
  {
    display: t("common:matches", "Matches"),
    isStat: true,
  },
];

function TableView({ searchText }) {
  const { t } = useTranslation();
  const { searchParams } = useRoute();

  const data = useGetMapData(searchParams, searchText);
  const cols = COLS(t);
  const rows = useMemo(() => {
    if (!data) return null;
    return data.map((stats) => {
      return [
        {
          display: (
            <MapCol>
              <MapBlock mapKey={stats.map.key} />
              <span className="type-body2">{stats.map.name}</span>
            </MapCol>
          ),
          link: `/valorant/lineups/${stats.map.key}`,
          value: stats.map.name,
        },
        {
          display: displayRate(stats.playRate, 100, 0, 1),
          value: stats.playRate,
        },
        {
          display: (
            <WinRateText
              className={"type-body2"}
              $score={stats.attackingRoundWinRate}
            >
              {displayRate(stats.attackingRoundWinRate, 100, 0, 1)}
            </WinRateText>
          ),
          value: stats.attackingRoundWinRate,
        },
        {
          display: (
            <WinRateText
              className={"type-body2"}
              $score={stats.defendingRoundWinRate}
            >
              {displayRate(stats.attackingRoundWinRate, 100, 0, 1)}
            </WinRateText>
          ),
          value: stats.defendingRoundWinRate,
        },
        {
          display: getLocaleString(stats.matches, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }),
          value: stats.matches,
        },
      ];
    });
  }, [data]);

  return (
    <DataTable indexCol cols={cols} rows={rows} sortCol={2} sortDir="DESC" />
  );
}

const Filters = ({ searchText, setSearch }) => {
  const { searchParams, currentPath } = useRoute();
  const { t } = useTranslation();

  const { act, rank, mode } = getStatsParams(
    searchParams,
    "maps",
  ) as MapStatsParams;

  const updateFilters = useCallback(
    (key, val) => {
      updateStatsParams({ searchParams, currentPath }, { [key]: val }, "maps");
    },
    [searchParams, currentPath],
  );

  const isCompetitive = useMemo(() => mode === "competitive", [mode]);
  return (
    <FilterBar>
      <TextInput
        defaultValue={searchText}
        placeholder={t("val:search.maps", "Search Maps")}
        onValueChange={setSearch}
      />
      <SelectMode
        stats
        selected={mode}
        onChange={(v) => updateFilters("mode", v)}
      />
      {isCompetitive ? (
        <SelectRank
          selected={rank}
          onChange={(v) => updateFilters("rank", v)}
        />
      ) : null}
      <SelectAct
        selected={act}
        onChange={(v) => updateFilters("act", v)}
        inputStyle
      />
    </FilterBar>
  );
};

export default function Agents() {
  const [searchText, setSearch] = useState("");

  return (
    <ContentContainer className="flex column gap-sp-4">
      <Filters searchText={searchText} setSearch={setSearch} />
      <TableView searchText={searchText} />
    </ContentContainer>
  );
}
